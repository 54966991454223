.action-button {
    bottom: 2em;
    right: 3em;
    position: fixed;
}

.action-button > button {
    border-radius: 50%;
    width: 5em;
    height: 5em;
    box-shadow: 1px 1px 1px rgba(64, 64, 64, .5);
}